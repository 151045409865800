import React from 'react';
import styles from './Filters.module.css';
import { TicketClassifier } from '../../../@Types/TicketTypes/Ticket';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import RoundedSelect from '../../../shared/RoundedSelect/RoundedSelect';
import { MenuItem } from '@material-ui/core';
import RoundedDatePicker from '../../../shared/@Pickers/RoundedDatePicker/RoundedDatePicker';
import RoundedClassifierPicker from '../../../shared/@Pickers/RoundedClassifierPicker/RoundedClassifierPicker';
import { endOfDay, startOfDay } from 'date-fns';
import RoundedCompanyPicker from '../../../shared/@Pickers/RoundedCompanyPicker/RoundedCompanyPicker';
import RoundedAutoComplete from '../../../shared/@Pickers/RoundedOrgAreaPicker/RoundedAutoComplete';
import { Company } from '../../../@Types/Company';
import { Agent } from '../../../@Types/Agent';
import { State } from '../../../@Types/State';
import RoundedAgentPicker from '../../../shared/@Pickers/RoundedAgentPicker/RoundedAgentPicker';
import { Metrics } from './Metrics';
import RoundedStatePicker from '../../../shared/@Pickers/RoundedStatePicker/RoundedStatePicker';
import RoundedProjectPicker from '../../../shared/@Pickers/RoundedProjectPicker/RoundedProjectPicker';
import { SiteState } from '../../../controllers/_SiteController/SiteReducer';
import FormStepTypes from '../../../constants/FormStepTypes';
import RoundedEntityValuePicker from '../../../shared/@Pickers/RoundedEntityValuePicker/RoundedEntityValuePicker';
import RoundedGenericPicker from '../../../shared/@Pickers/RoundedGenericPicker/RoundedGenericPicker';
import { useAppSelector, useCurrentProject } from '../../../hooks';
import { EntityValue } from '../../../@Types/EntityValue';
import RoundedMultiClassifierPicker from './MultiClassifierPicker/RoundedMultiClassifierPicker/RoundedMultiClassifierPicker';
import { classifiersStateData } from './MultiClassifierPicker/ClassifyMenu/ClassifyMenu';

interface FilterProps {
    allowedFilters: AllowedFilters;
    filters: AnalyticFilters;
    setFilters: (filters: AnalyticFilters) => void;
    /** if app is currently mobile */
    mobile: boolean;
}

export enum BaseFilters {
    classifiers = 'classifiers',
    classifiersMultiple = 'classifiersMultiple',
    firstClassifiers = 'firstClassifiers',
    secondClassifiers = 'secondClassifiers',
    temporality = 'temporality',
    temporality2 = 'temporality2',
    metric = 'metric',
    dates = 'dates',
    dates2 = 'dates2',
    companies = 'companies',
    agents = 'agents',
    agents2 = 'agents2',
    area = 'area',
    states = 'states',
    project = 'project',
    macroProject = 'macroProject',
    lvpEstadoInmueble = 'lvpEstadoInmueble',
    lvpEstadoPosventa = 'lvpEstadoPosventa',
    ariasOrigenPosventa = 'ariasOrigenPosventa',
    prodesaRegional = 'prodesaRegional',
    responseStates = 'responseStates',
    onVacations = 'onVacations',
}

type BaseFiltersType = {
    [key in BaseFilters]?: boolean;
};

export interface AllowedFilters extends BaseFiltersType {
    values?: string[];
    entities?: { idEntity: string; multiple: boolean }[];
}

export interface AnalyticFilters {
    classifiers?: TicketClassifier[];
    classifiersMultiple?: classifiersStateData;
    classifier?: TicketClassifier & { children: string[] };
    metric?: Metrics;
    groupClassifier?: boolean;
    secondClassifier?: TicketClassifier & { children: string[] };
    groupSecondClassifier?: boolean;
    temporality?: string;
    temporality2?: string;
    startDate?: Date | null;
    endDate?: Date | null;
    startDate2?: Date | null;
    endDate2?: Date | null;
    companies?: Company[];
    states?: State[];
    agents?: Agent[];
    agents2?: Agent[];
    orgArea?: string;
    CBR?: {
        project?: { id: string; label: string; idMacroProject: string };
        macroProject?: { id: string; label: string };
    };
    idOrganization?: string;
    values: Record<string, any>;
    entities: Record<string, EntityValue[]>;
    lvpEstadoInmueble?: string[];
    lvpEstadoPosventa?: string[];
    ariasOrigenPosventa?: string[];
    prodesaRegional?: string[];
    responseStates?: string[];
    onVacations?: boolean;
}

export function parseAnalyticFilters(
    siteInfo: SiteState,
    filters: AnalyticFilters,
    allowedFilters: AllowedFilters
): string {
    const url = new URLSearchParams();

    if (filters.classifiers) {
        url.set(
            'classifiers',
            filters.classifiers
                .map((classifier) => classifier.idValue)
                .flat()
                .join(',')
        );
    }

    if (
        filters.classifiersMultiple &&
        Object.keys(filters.classifiersMultiple).length > 0
    ) {
        url.set(
            'classifiersMultiple',
            Object.entries(filters.classifiersMultiple)
                .map(
                    ([id, classifier]) =>
                        `${id}:${classifier.idValuesSelected.join(
                            ','
                        )}:${classifier.idPathSelected.join(
                            ','
                        )}:${classifier.idValuesExcluded.join(
                            ','
                        )}:${classifier.idPathExcluded.join(',')}:`
                )
                .join(';')
        );
    }

    if (filters.classifier) {
        if (
            !filters.groupClassifier &&
            filters.classifier.children.length > 0
        ) {
            url.set('firstClassifiers', filters.classifier.children.join(','));
        } else {
            url.set('firstClassifiers', filters.classifier.idValue);
        }
    }

    if (filters.secondClassifier) {
        const key = filters.classifier
            ? 'secondClassifiers'
            : 'firstClassifiers';
        if (
            !filters.groupSecondClassifier &&
            filters.secondClassifier.children.length > 0
        ) {
            url.set(key, filters.secondClassifier.children.join(','));
        } else {
            url.set(key, filters.secondClassifier.idValue);
        }
    }

    if (filters.temporality) {
        url.set('temporality', filters.temporality);
    }
    if (filters.temporality2) {
        url.set('temporality2', filters.temporality2);
    }

    if (filters.startDate) {
        url.set('startDate', startOfDay(filters.startDate).toISOString());
    }

    if (filters.endDate) {
        url.set('endDate', endOfDay(filters.endDate).toISOString());
    }

    if (filters.startDate2) {
        url.set('startDate2', startOfDay(filters.startDate2).toISOString());
    }

    if (filters.endDate2) {
        url.set('endDate2', endOfDay(filters.endDate2).toISOString());
    }

    if (filters.companies && filters.companies.length > 0) {
        url.set('idCompanies', filters.companies.map((c) => c._id).join(','));
    }

    if (filters.agents && filters.agents.length > 0) {
        url.set('idAgents', filters.agents.map((c) => c._id).join(','));
    }
    if (filters.agents2 && filters.agents2.length > 0) {
        url.set('idAgents2', filters.agents2.map((c) => c._id).join(','));
    }

    if (filters.orgArea) {
        url.set('area', filters.orgArea);
    }

    if (filters.states && filters.states.length > 0) {
        url.set('states', filters.states.map((c) => c._id).join(','));
    }

    if (filters.CBR?.project) {
        url.set('project', filters.CBR.project.label);
    }

    if (filters.CBR?.macroProject) {
        url.set('macroProject', filters.CBR.macroProject.id);
    }
    if (filters.idOrganization) {
        url.set('idOrganization', filters.idOrganization);
    }

    if (filters.lvpEstadoInmueble && filters.lvpEstadoInmueble.length > 0) {
        url.set('lvpEstadoInmueble', filters.lvpEstadoInmueble.join(','));
    }

    if (filters.lvpEstadoPosventa && filters.lvpEstadoPosventa.length > 0) {
        url.set('lvpEstadoPosventa', filters.lvpEstadoPosventa.join(','));
    }
    if (filters.ariasOrigenPosventa && filters.ariasOrigenPosventa.length > 0) {
        url.set('ariasOrigenPosventa', filters.ariasOrigenPosventa.join(','));
    }
    if (filters.responseStates && filters.responseStates.length > 0) {
        url.set('responseStates', filters.responseStates.join(','));
    }

    if (filters.prodesaRegional && filters.prodesaRegional.length > 0) {
        url.set('prodesaRegional', filters.prodesaRegional.join(','));
    }

    if (filters.onVacations) {
        url.set('onVacations', filters.onVacations.toString());
    }

    if (filters.values) {
        const project = siteInfo.projects[siteInfo.idProject ?? ''];
        for (const [idValue, value] of Object.entries(filters.values)) {
            const projectValue = project.values.values[idValue];
            switch (projectValue?.type) {
                case FormStepTypes.ENTITYVALUEPICKER:
                    if (value !== undefined) {
                        url.set(
                            `ticket.values.${idValue}`,
                            value.map((v: any) => v._id).join(';')
                        );
                    }
                    break;
            }
        }
    }
    if (filters.entities) {
        for (const [idEntity, entityValues] of Object.entries(
            filters.entities
        )) {
            url.set(
                `entities.${idEntity}.${
                    allowedFilters.entities?.find(
                        (entity) => entity.idEntity === idEntity
                    )?.multiple
                }`,
                entityValues.map((v) => v._id).join(',')
            );
        }
    }

    const parsedUrl = url.toString();
    return parsedUrl ? `?${parsedUrl}` : '';
}

function Filters({
    mobile,
    filters,
    setFilters,
    allowedFilters,
}: FilterProps): JSX.Element {
    const project = useCurrentProject();
    const siteClassifiers = useSelector(
        (state: RootState) => state.site.classifiers
    );
    const entities = useAppSelector((state) => state.site.entities);
    if (!project) return <></>;
    return (
        <React.Fragment>
            <div
                className={
                    (mobile ? styles.mobileContainer : styles.container) +
                    ' flexbox'
                }
            >
                {allowedFilters.states && (
                    <div className={styles.selectContainer}>
                        <RoundedStatePicker
                            value={filters.states ?? []}
                            options={Object.values(project.states)}
                            handleUpdate={(states): void => {
                                setFilters({ ...filters, states });
                            }}
                            label={'Estados'}
                            multiple={true}
                        />
                    </div>
                )}
                {allowedFilters.macroProject && (
                    <div className={styles.selectContainer}>
                        <RoundedProjectPicker
                            macroProjects
                            value={filters.CBR?.macroProject}
                            height="31px"
                            backgroundColor="white"
                            label={'Macro Proyecto'}
                            onChange={(macroProject: any): void => {
                                setFilters({
                                    ...filters,
                                    CBR: {
                                        ...(filters.CBR ?? {}),
                                        macroProject,
                                    },
                                });
                            }}
                        />
                    </div>
                )}
                {allowedFilters.project && (
                    <div className={styles.selectContainer}>
                        <RoundedProjectPicker
                            idMacroProject={filters.CBR?.macroProject?.id}
                            value={filters.CBR?.project}
                            height="31px"
                            backgroundColor="white"
                            label={'Proyecto'}
                            onChange={(project: any): void => {
                                setFilters({
                                    ...filters,
                                    CBR: { ...(filters.CBR ?? {}), project },
                                });
                            }}
                        />
                    </div>
                )}

                {allowedFilters.metric && (
                    <div className={styles.selectContainer}>
                        <RoundedSelect
                            minWidth={210}
                            height="31px"
                            value={filters.metric}
                            label="Métrica"
                            containerMargin="0px"
                            handleUpdate={(event): void => {
                                setFilters({
                                    ...filters,
                                    metric: event.target.value,
                                });
                            }}
                        >
                            <MenuItem value={Metrics.CANT_CASOS}>
                                Cantidad de casos
                            </MenuItem>
                            <MenuItem value={Metrics.REPLY_TIME}>
                                Tiempo de respuesta
                            </MenuItem>
                            <MenuItem value={Metrics.CLOSE_TIME}>
                                Tiempo de cierre
                            </MenuItem>
                            <MenuItem value={Metrics.RATING}>
                                Calificación
                            </MenuItem>
                        </RoundedSelect>
                    </div>
                )}
                {allowedFilters.classifiers && (
                    <div className={styles.selectContainer}>
                        <RoundedClassifierPicker
                            mobile={mobile}
                            showParentToggle
                            multiple
                            allowRoots
                            label="Clasificadores"
                            handleUpdate={(
                                classifiers: TicketClassifier[]
                            ): void => {
                                setFilters({
                                    ...filters,
                                    classifiers,
                                });
                            }}
                            value={filters.classifiers ?? []}
                        />
                    </div>
                )}
                {allowedFilters.classifiersMultiple && (
                    <div className={styles.selectContainer}>
                        <RoundedMultiClassifierPicker
                            mobile={mobile}
                            showParentToggle
                            multiple
                            allowRoots
                            label="Clasificadores"
                            handleUpdate={(
                                classifiers: classifiersStateData
                            ): void => {
                                setFilters({
                                    ...filters,
                                    classifiersMultiple: classifiers,
                                });
                            }}
                            value={filters.classifiersMultiple ?? {}}
                        />
                    </div>
                )}
                {allowedFilters.firstClassifiers && (
                    <div className={styles.selectContainer}>
                        <RoundedClassifierPicker
                            mobile={mobile}
                            showParentToggle
                            multiple={true}
                            allowRoots
                            label="Clasificador"
                            handleUpdate={(
                                classifiers: TicketClassifier[]
                            ): void => {
                                setFilters({
                                    ...filters,
                                    classifier: classifiers[0]
                                        ? {
                                              ...classifiers[0],
                                              children: classifiers[0].idValue
                                                  ? siteClassifiers[
                                                        classifiers[0].idValue
                                                    ].children
                                                  : [],
                                          }
                                        : undefined,
                                });
                            }}
                            parentValue={filters.groupClassifier}
                            handleParentToggleChange={(
                                groupClassifier: boolean
                            ): void => {
                                setFilters({
                                    ...filters,
                                    groupClassifier,
                                });
                            }}
                            value={
                                filters.classifier ? [filters.classifier] : []
                            }
                        />
                    </div>
                )}
                {allowedFilters.secondClassifiers && (
                    <div className={styles.selectContainer}>
                        <RoundedClassifierPicker
                            mobile={mobile}
                            showParentToggle
                            multiple={false}
                            allowRoots
                            label="Segundo Clasificador"
                            handleUpdate={(
                                classifiers: TicketClassifier[]
                            ): void => {
                                setFilters({
                                    ...filters,
                                    secondClassifier: classifiers[0]
                                        ? {
                                              ...classifiers[0],
                                              children: classifiers[0].idValue
                                                  ? siteClassifiers[
                                                        classifiers[0].idValue
                                                    ].children
                                                  : [],
                                          }
                                        : undefined,
                                    temporality:
                                        filters.temporality === '$dayOfYear'
                                            ? undefined
                                            : filters.temporality,
                                });
                            }}
                            parentValue={filters.groupSecondClassifier}
                            handleParentToggleChange={(
                                groupSecondClassifier: boolean
                            ): void => {
                                setFilters({
                                    ...filters,
                                    groupSecondClassifier,
                                });
                            }}
                            value={
                                filters.secondClassifier
                                    ? [filters.secondClassifier]
                                    : []
                            }
                        />
                    </div>
                )}
                {allowedFilters.temporality && (
                    <div className={styles.selectContainer}>
                        <RoundedSelect
                            minWidth={210}
                            height="31px"
                            value={filters.temporality}
                            label="Temporalidad"
                            containerMargin="0px"
                            handleUpdate={(event): void => {
                                setFilters({
                                    ...filters,
                                    temporality: event.target.value,
                                });
                            }}
                        >
                            <MenuItem value={'$month'}>Mes</MenuItem>
                            <MenuItem value={'$dayOfWeek'}>
                                Día de la semana
                            </MenuItem>
                            {(!filters.secondClassifier ||
                                filters.secondClassifier?.children.length ===
                                    0) && (
                                <MenuItem value={'$dayOfYear'}>Días</MenuItem>
                            )}
                            <MenuItem value={''}>Ninguna</MenuItem>
                        </RoundedSelect>
                    </div>
                )}
                {allowedFilters.temporality2 && (
                    <div className={styles.selectContainer}>
                        <RoundedSelect
                            minWidth={210}
                            height="31px"
                            value={filters.temporality2}
                            label="Temporalidad"
                            containerMargin="0px"
                            handleUpdate={(event): void => {
                                setFilters({
                                    ...filters,
                                    temporality2: event.target.value,
                                });
                            }}
                        >
                            <MenuItem value={'$month'}>Mensual</MenuItem>
                            <MenuItem value={'$week'}>Semanal</MenuItem>
                            <MenuItem value={'$dayOfYear'}>Diaria</MenuItem>
                        </RoundedSelect>
                    </div>
                )}
                {allowedFilters.companies && (
                    <div className={styles.selectContainer}>
                        <RoundedCompanyPicker
                            value={filters.companies ?? []}
                            handleUpdate={(companies): void => {
                                setFilters({
                                    ...filters,
                                    companies,
                                });
                            }}
                            label={'Empresa'}
                            multiple={true}
                        />
                    </div>
                )}
                {allowedFilters.area && (
                    <div className={styles.selectContainer}>
                        <RoundedAutoComplete
                            value={filters.orgArea}
                            handleUpdate={(orgArea: string): void => {
                                setFilters({
                                    ...filters,
                                    orgArea,
                                });
                            }}
                            creatable={false}
                            placeholder="Área"
                            height={31}
                        />
                    </div>
                )}
                {allowedFilters.agents && (
                    <div className={styles.selectContainer}>
                        <RoundedAgentPicker
                            multiple={true}
                            value={filters.agents ?? []}
                            backgroundColor="transparent"
                            label={'Agentes'}
                            handleUpdate={(agents): void => {
                                setFilters({
                                    ...filters,
                                    agents,
                                });
                            }}
                        />
                    </div>
                )}
                {allowedFilters.agents2 && (
                    <div className={styles.selectContainer}>
                        <RoundedAgentPicker
                            multiple={true}
                            value={filters.agents2 ?? []}
                            backgroundColor="transparent"
                            label={'Agentes 2'}
                            handleUpdate={(agents2): void => {
                                setFilters({
                                    ...filters,
                                    agents2,
                                });
                            }}
                        />
                    </div>
                )}
                {allowedFilters.values &&
                    allowedFilters.values.map((idValue) => {
                        const projectValue = project.values.values[idValue];
                        switch (projectValue?.type) {
                            case FormStepTypes.ENTITYVALUEPICKER:
                                if (!projectValue.idEntity) return <div></div>;
                                return (
                                    <div className={styles.selectContainer}>
                                        <RoundedEntityValuePicker
                                            idEntity={projectValue.idEntity}
                                            value={
                                                filters.values[idValue]
                                                    ? filters.values[idValue]
                                                    : []
                                            }
                                            height="31px"
                                            label={projectValue.label}
                                            required={false}
                                            multiple={true}
                                            handleUpdate={(value): void => {
                                                setFilters({
                                                    ...filters,
                                                    values: {
                                                        ...filters.values,
                                                        [idValue]: value,
                                                    },
                                                });
                                            }}
                                        />
                                    </div>
                                );

                            default:
                                return <></>;
                        }
                    })}
                {allowedFilters.prodesaRegional && (
                    <div className={styles.selectContainer}>
                        <RoundedGenericPicker
                            label={'Regional Prodesa'}
                            elementLabel="Regional"
                            calcId={(estado): string => estado}
                            value={filters.prodesaRegional ?? []}
                            calcLbl={(estado): string => {
                                return estado;
                            }}
                            multiple={true}
                            loadInitialType={async (
                                ids: string[]
                            ): Promise<string[]> => {
                                return ids;
                            }}
                            handleUpdate={(prodesaRegional): void => {
                                setFilters({
                                    ...filters,
                                    prodesaRegional,
                                });
                            }}
                            loadElements={async (
                                page: number,
                                pageSize: number,
                                search: string | undefined
                            ): Promise<string[]> => {
                                let elements = [
                                    '01 BOGOTA',
                                    '02 CARIBE',
                                    '03 CENTRO',
                                    '04 OCCIDENTE',
                                ];
                                if (!search) return elements;
                                const regex = new RegExp(search, 'gi');
                                elements = elements.filter((estado) =>
                                    regex.test(estado)
                                );
                                return elements;
                            }}
                        />
                    </div>
                )}
                {allowedFilters.entities &&
                    allowedFilters.entities.map(({ idEntity, multiple }) => {
                        const entity = entities[idEntity];
                        if (!entity) return <></>;
                        return (
                            <div className={styles.selectContainer}>
                                <RoundedEntityValuePicker
                                    idEntity={idEntity}
                                    value={filters.entities[idEntity] ?? []}
                                    height="31px"
                                    label={
                                        multiple
                                            ? entity.pluralName
                                            : entity.name
                                    }
                                    required={false}
                                    multiple={multiple}
                                    handleUpdate={(entityValues): void => {
                                        setFilters({
                                            ...filters,
                                            entities: {
                                                ...filters.entities,
                                                [idEntity]: entityValues,
                                            },
                                        });
                                    }}
                                />
                            </div>
                        );
                    })}
                {allowedFilters.dates && (
                    <React.Fragment>
                        <div className={styles.selectContainer}>
                            <RoundedDatePicker
                                value={filters.startDate ?? null}
                                height="31px"
                                backgroundColor="white"
                                label={'Fecha Inicio'}
                                required={false}
                                clearLabel="Borrar"
                                clearable
                                onChange={(startDate): void => {
                                    setFilters({
                                        ...filters,
                                        startDate,
                                    });
                                }}
                            />
                        </div>
                        <div className={styles.selectContainer}>
                            <RoundedDatePicker
                                value={filters.endDate ?? null}
                                height="31px"
                                backgroundColor="white"
                                label={'Fecha Fin'}
                                clearLabel="Borrar"
                                clearable
                                required={false}
                                onChange={(endDate): void => {
                                    setFilters({
                                        ...filters,
                                        endDate,
                                    });
                                }}
                            />
                        </div>
                    </React.Fragment>
                )}
                {allowedFilters.dates2 && (
                    <React.Fragment>
                        <div className={styles.selectContainer}>
                            <RoundedDatePicker
                                value={filters.startDate2 ?? null}
                                height="31px"
                                backgroundColor="white"
                                label={'Fecha Inicio 2'}
                                required={false}
                                clearLabel="Borrar"
                                clearable
                                onChange={(startDate2): void => {
                                    setFilters({
                                        ...filters,
                                        startDate2,
                                    });
                                }}
                            />
                        </div>
                        <div className={styles.selectContainer}>
                            <RoundedDatePicker
                                value={filters.endDate2 ?? null}
                                height="31px"
                                backgroundColor="white"
                                label={'Fecha Fin 2'}
                                clearLabel="Borrar"
                                clearable
                                required={false}
                                onChange={(endDate2): void => {
                                    setFilters({
                                        ...filters,
                                        endDate2,
                                    });
                                }}
                            />
                        </div>
                    </React.Fragment>
                )}
                {allowedFilters.lvpEstadoInmueble && (
                    <div className={styles.selectContainer}>
                        <RoundedGenericPicker<string>
                            label={'Estado Inmueble'}
                            elementLabel="Estado"
                            calcId={(estado): string => estado}
                            value={filters.lvpEstadoInmueble ?? []}
                            calcLbl={(estado): string => {
                                return estado;
                            }}
                            multiple={true}
                            loadInitialType={async (
                                ids: string[]
                            ): Promise<string[]> => {
                                return ids;
                            }}
                            handleUpdate={(lvpEstadoInmueble): void => {
                                setFilters({
                                    ...filters,
                                    lvpEstadoInmueble,
                                });
                            }}
                            loadElements={async (
                                page: number,
                                pageSize: number,
                                search: string | undefined
                            ): Promise<string[]> => {
                                let elements = [
                                    'Disponible',
                                    'Vendido',
                                    'Pendiente Preentrega',
                                    'Inasistencia Preentrega',
                                    'Preentregado',
                                    'Descargado',
                                    'Saldado',
                                    'Pendiente Entrega de llaves',
                                    'Inasistencia Entrega de llaves',
                                    'Entregado',
                                ];
                                if (!search) return elements;
                                const regex = new RegExp(search, 'gi');
                                elements = elements.filter((estado) =>
                                    regex.test(estado)
                                );
                                return elements;
                            }}
                        />
                    </div>
                )}
                {allowedFilters.lvpEstadoPosventa && (
                    <div className={styles.selectContainer}>
                        <RoundedGenericPicker<string>
                            label={'Estado Posventa'}
                            elementLabel="Estado"
                            calcId={(estado): string => estado}
                            value={filters.lvpEstadoPosventa ?? []}
                            calcLbl={(estado): string => {
                                return estado;
                            }}
                            multiple={true}
                            loadInitialType={async (
                                ids: string[]
                            ): Promise<string[]> => {
                                return ids;
                            }}
                            handleUpdate={(lvpEstadoPosventa): void => {
                                setFilters({
                                    ...filters,
                                    lvpEstadoPosventa,
                                });
                            }}
                            loadElements={async (
                                page: number,
                                pageSize: number,
                                search: string | undefined
                            ): Promise<string[]> => {
                                let elements = [
                                    'Pendiente visita',
                                    'Pendiente aprobación daños',
                                    'Pendiente de reparación',
                                    'Reparado',
                                ];
                                if (!search) return elements;
                                const regex = new RegExp(search, 'gi');
                                elements = elements.filter((estado) =>
                                    regex.test(estado)
                                );
                                return elements;
                            }}
                        />
                    </div>
                )}
                {allowedFilters.ariasOrigenPosventa && (
                    <div className={styles.selectContainer}>
                        <RoundedGenericPicker<string>
                            label={'Origen Posventa'}
                            elementLabel="Origen"
                            calcId={(estado): string => estado}
                            value={filters.ariasOrigenPosventa ?? []}
                            calcLbl={(estado): string => {
                                return estado;
                            }}
                            multiple={false}
                            loadInitialType={async (
                                ids: string[]
                            ): Promise<string[]> => {
                                return ids;
                            }}
                            handleUpdate={(ariasOrigenPosventa): void => {
                                setFilters({
                                    ...filters,
                                    ariasOrigenPosventa,
                                });
                            }}
                            loadElements={async (
                                page: number,
                                pageSize: number,
                                search: string | undefined
                            ): Promise<string[]> => {
                                let elements = ['Posventa', 'Entrega'];
                                if (!search) return elements;
                                const regex = new RegExp(search, 'gi');
                                elements = elements.filter((estado) =>
                                    regex.test(estado)
                                );
                                return elements;
                            }}
                        />
                    </div>
                )}
                {allowedFilters.responseStates && (
                    <div className={styles.selectContainer}>
                        <RoundedGenericPicker<string>
                            label={'Estado Respuesta'}
                            elementLabel="Estado"
                            calcId={(estado): string => estado}
                            value={filters.responseStates ?? []}
                            calcLbl={(estado): string => {
                                return estado;
                            }}
                            multiple={true}
                            loadInitialType={async (
                                ids: string[]
                            ): Promise<string[]> => {
                                return ids;
                            }}
                            handleUpdate={(responseStates): void => {
                                setFilters({
                                    ...filters,
                                    responseStates,
                                });
                            }}
                            loadElements={async (
                                page: number,
                                pageSize: number,
                                search: string | undefined
                            ): Promise<string[]> => {
                                let elements = [
                                    'Respondido a tiempo',
                                    'Respondido vencido',
                                    'No respondido no vencido',
                                    'No respondido vencido',
                                ];
                                if (!search) return elements;
                                const regex = new RegExp(search, 'gi');
                                elements = elements.filter((estado) =>
                                    regex.test(estado)
                                );
                                return elements;
                            }}
                        />
                    </div>
                )}
                {allowedFilters.onVacations && (
                    <div className={styles.selectContainer}>
                        <RoundedSelect
                            minWidth={210}
                            height="31px"
                            value={filters.onVacations}
                            label="En Vacaciones"
                            containerMargin="0px"
                            handleUpdate={(event): void => {
                                setFilters({
                                    ...filters,
                                    onVacations: event.target.value,
                                });
                            }}
                        >
                            <MenuItem value={'true'}>Sí</MenuItem>
                            <MenuItem value={'false'}>No</MenuItem>
                        </RoundedSelect>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

export default Filters;
